;(($, window, document, undefined) => {

    $.extend(window.yunu, {
        download(config){
            var ajax = function (id) {
                if (!config['uid']) {
                    yunu.swal({
                        text: '请登录',
                        confirmButtonText: "登录"
                    }, function(){
                        swal.close();
                        $.ajax({
                            url: config['url'],
                            type: 'POST',
                            data: {id: id},
                            not_login: true
                        });
                        yunu.login();
                        // window.location.href = config['login'];
                    });
                    return;
                }
                yunu.swal($.extend({text: "每个注册用户每天至多下载3个源文件，是否确定下载"}, config['swal']), function () {
                    if (config['swal'] && config['swal']['showCancelButton'] === false) {
                        // 没有下载权限
                        swal.close();
                        return false;
                    }
                    $.ajax({
                        url: config['url'],
                        type: 'POST',
                        data: {id: id}
                    })
                    .done(function(res) {
                        if (res.state == 0) {
                            swal("错误", res.mess , "error");
                        }
                        if (res.state == 403) {
                            yunu.swal({
                                text: res.mess,
                                confirmButtonText: "登录"
                            }, function(){
                                swal.close();
                                yunu.login();
                                // window.location.href = config['login'];
                            });
                        }
                        if (res.state == 1) {
                            swal.close();
                            // var newTab = window.open('about:blank');
                            window.location.href = config['url']+'?id='+id+'&download_key='+res.download_key;
                            if (config['reload']) {
                                window.location.reload();
                            }
                            //window.open(config['url']+'?id='+id+'&download_key='+res.download_key);
                        }
                    })
                    .fail(function(res) {
                        swal("错误", res.status + " " + res.statusText, "error");
                    });
                });
            }
            $(config['btn']).on('click', function (event) {
                event.stopPropagation();
                var id = $(this).closest('li').attr('data-id');
                ajax(id);
            });
            if (config['id'] && config['uid']) {
                ajax(config['id']);
            }
        },
        validate_class: (function () {
            var adopt = true;
            return {
                init: ()=>adopt = true,
                result: ()=>adopt,
                validate(obj, condition, error_class) {
                    var items = obj.closest('.form_items,.form_items_b');
                    var fn = function () {
                        items.removeClass('empty error');
                        if (condition()) {
                            obj.val() == ''?items.addClass('empty'):items.addClass('error');
                        }
                    }
                    fn();
                    obj.off('input').on('input', fn);
                    if (condition()) {
                        return adopt = false;
                    }else{
                        return true;
                    }
                }
            }
        })(),
        validation(validation_rule) {
            yunu.validate_class.init();
            var validate = yunu.validate_class.validate;
            $.each(validation_rule, (k,v)=>{v(validate)});
            var adopt = yunu.validate_class.result();
            if (!adopt) {
                $('.empty, .error').first().find('input').focus();
            }
            return adopt;
        },
        submit(url, form, submit, validation_rule, layer, callback) {
            form.on('submit', function () {
                submit.prop('disabled', true).addClass('disabled');
                setTimeout(function () {
                    submit.prop('disabled', false).removeClass('disabled');
                }, 3000);
                if (yunu.validation(validation_rule)) {
                    layer.load(0, {
                        skin: 'msg_loading',
                        shade: [0.1, '#000']
                    });
                    $.ajax({
                        url: url,
                        type: 'POST',
                        data: form.serialize(),
                        xhrFields: {withCredentials: true},
                    }).done(function(res) {
                        if (res.state == 1) {
                            callback(res);
                        }else{
                            layer.msg(res.mess, {
                                skin: 'layui-layer-hui msg_error',
                                offset: 't',
                                anim: 5,
                                time: 3000
                            });
                        }
                    }).always(function() {
                        layer.closeAll('loading');
                    });
                    // $.post(url, form.serialize(), function(res){
                    //     if (res.state == 1) {
                    //         callback(res);
                    //     }else{
                    //         layer.msg(res.mess, {
                    //             skin: 'layui-layer-hui msg_error',
                    //             offset: 't',
                    //             anim: 5,
                    //             time: 3000
                    //         });
                    //     }
                    // }).complete(function () {
                    //     layer.closeAll('loading');
                    // });
                }
                return false;
            });
        },
        yzm(form, tel, layer, no_validate_tel) {
            var send_yzm = form.find('#yzm a');
            var num = 0;
            var timer = null;
            var tel_val = null;
            var wait = function () {
                clearInterval(timer);
                num = 60;
                send_yzm.html(num + '秒后重试').addClass('disabled');
                timer = setInterval(function () {
                    num--;
                    send_yzm.html(num + '秒后重试');
                    if (num <= 0) {
                        clearInterval(timer);
                        send_yzm.html('发送验证码').removeClass('disabled');
                    };
                }, 1000);
            };
            var sms = function (validate) {
                wait();
                $.ajax({
                    url: global_app+"/Sms/index.html",
                    type: 'POST',
                    data: {
                        tel: tel_val,
                        geetest_challenge: validate.geetest_challenge,
                        geetest_validate: validate.geetest_validate,
                        geetest_seccode: validate.geetest_seccode
                    },
                    xhrFields: {withCredentials: true},
                }).done(function(result) {
                    if (result.code != 0) {
                        num = 0;
                        alert(result.msg+(!!result.detail?' : '+result.detail:''));
                        tel.focus();
                    }
                });
                // $.post(global_app+"/Sms/index.html", {
                //     tel: tel_val,
                //     geetest_challenge: validate.geetest_challenge,
                //     geetest_validate: validate.geetest_validate,
                //     geetest_seccode: validate.geetest_seccode
                // }, function(result){
                //     // console.log(result);
                //     if (result.code != 0) {
                //         num = 0;
                //         alert(result.msg+(!!result.detail?' : '+result.detail:''));
                //         tel.focus();
                //     }
                // });
            }
            //验证码发送
            var handlerEmbed = function (captchaObj) {
                captchaObj.onReady(function () {
                }).onSuccess(function () {
                    var validate = captchaObj.getValidate();
                    if (!validate) {
                        return alert('请完成验证');
                    }
                    if (no_validate_tel) {
                        sms(validate);
                    } else {
                        layer.load(0, {
                            skin: 'msg_loading',
                            shade: [0.1, '#000']
                        });
                        $.post(global_app+"/Login/validate_tel.html", {tel: tel_val}, function(result){
                            if (result.state == 1) {
                                sms(validate);
                            }else{
                                layer.msg(result.mess, {
                                    skin: 'layui-layer-hui msg_error',
                                    offset: 't',
                                    anim: 5,
                                    time: 3000
                                });
                                tel.focus();
                                captchaObj.reset();
                            }
                        }).complete(function() { layer.closeAll('loading'); });
                    }
                });
                send_yzm.on('click', function () {
                    tel_val = tel.val();
                    if (num > 0) return;
                    //手机号码验证
                    var tel_validate = yunu.validate_class.validate(tel, function () {
                        return !tel_val.match(/^1\d{10}$/);
                    });
                    if (tel_validate) {
                        captchaObj.verify();
                        // if (no_validate_tel) {
                        //     sms();
                        // } else {
                        //     layer.load(0, {
                        //         skin: 'msg_loading',
                        //         shade: [0.1, '#000']
                        //     });
                        //     $.post(global_app+"/Login/validate_tel.html", {tel: tel_val}, function(result){
                        //         if (result.state == 1) {
                        //             sms();
                        //         }else{
                        //             layer.msg(result.mess, {
                        //                 skin: 'layui-layer-hui msg_error',
                        //                 offset: 't',
                        //                 anim: 5,
                        //                 time: 3000
                        //             });
                        //             tel.focus();
                        //             captchaObj.reset();
                        //         }
                        //     }).complete(function() { layer.closeAll('loading'); });
                        // }
                    }
                });
            };
            $.ajax({
                // 获取id，challenge，success（是否启用failback）
                url: global_app+"/Sms/getVerify/t/"+new Date().getTime(), // 加随机数防止缓存
                type: "get",
                dataType: "json",
                xhrFields: {withCredentials: true},
                success: function (data) {
                    // 使用initGeetest接口
                    // 参数1：配置参数
                    // 参数2：回调，回调的第一个参数验证码对象，之后可以使用它做appendTo之类的事件
                    initGeetest({
                        gt: data.gt,
                        challenge: data.challenge,
                        new_captcha: data.new_captcha,
                        product: "bind", // 产品形式，包括：float，embed，popup。注意只对PC版验证码有效
                        offline: !data.success // 表示用户后台检测极验服务器是否宕机，一般不需要关注
                        // 更多配置参数请参见：http://www.geetest.com/install/sections/idx-client-sdk.html#config
                    }, handlerEmbed);
                }
            });
        },
        register(callback) {
            layui.use('layer', function(){
                layer = layui.layer;
                layer.closeAll('page');

                let hmsr = localStorage.getItem('hmsr');
                let hmsr_input = '';
                if (hmsr) {
                    hmsr = JSON.parse(hmsr);
                    for (let k in hmsr) {
                        hmsr_input += `<input type="hidden" name="${k}" value="${hmsr[k]}" />`;
                    }
                }

                layer.open({
                    type: 1,
                    skin: 'login_layer',
                    title: false,
                    area: ['415px','593px'],
                    // shadeClose: true, //开启遮罩关闭
                    content: `<div class="login_popup">
                        <div class="login_popup_tit">免费注册</div>
                        <form id="form_register" method="POST">
                            ${hmsr_input}
                            <div class="form_items">
                                <input type="text" name="username" value="" placeholder="请输入您的用户名">
                                <font class="font_empty">请输入用户名</font>
                                <font class="font_error">用户已注册</font>
                            </div>
                            <div class="form_items">
                                <input id="password" type="password" name="password" value="" placeholder="请输入您的密码">
                                <font class="font_empty">请输入符合规范的密码，由字母+数字+符号组成</font>
                                <font class="font_error">由字母、数字组成，区分大小写（6~16位）</font>
                            </div>
                            <div class="form_items">
                                <input id="password2" type="password" name="password2" value="" placeholder="请再次输入您的密码">
                                <font class="font_empty">再次输入密码</font>
                                <font class="font_error">两次密码输入不一致。</font>
                            </div>
                            <div class="form_items">
                                <input type="number" name="qq" value="" placeholder="请输入您的QQ号码">
                                <font class="font_empty">请输入QQ号码</font>
                            </div>
                            <div class="form_items">
                                <input id="tel" type="tel" name="tel" value="" placeholder="请输入您的手机号码">
                                <font class="font_empty">请输入手机号码</font>
                                <font class="font_error">请输入正确手机号码</font>
                            </div>
                            <div class="form_items yzm" id="yzm">
                                <input type="text" name="smscode" value="" placeholder="请输入您的验证码">
                                <a href="javascript:;">获取验证码</a>
                                <font class="font_empty">请输入验证码</font>
                                <font class="font_error">验证不正确</font>
                            </div>
                            <div class="form_items">
                                <input class="disabled" type="submit" value="注  册" disabled>
                            </div>
                            <div class="form_items_b clearfix">
                                <div class="fl"><input class="mr10 mt5 vat" id="protocal" type="checkbox" />阅读并接受<a class="color" href="http://www.yunucms.com/notice/295.html ">《服务协议》</a></div>
                                <div class="fr"><a class="color" href="javascript:yunu.login();">返回登录 >></a></div>
                                <div class="clear ofh">
                                    <font class="font_error">请先阅读并同意《云优用户服务协议》</font>
                                </div>
                            </div>
                        </form>
                    </div>`,
                    success: function(layero, index){
                        var form = $('#form_register');
                        var input = form.find('input[type!="submit"]').not('#password, #password2, #tel, #protocal');
                        var pass = form.find('#password');
                        var pass2 = form.find('#password2');
                        var tel = form.find('#tel');
                        var protocal = form.find('#protocal');
                        var submit = form.find('input[type="submit"]');
                        yunu.yzm(form, tel, layer);
                        submit.prop('disabled', false).removeClass('disabled');
                        yunu.submit(global_app+"/Login/register.html", form, submit, [
                            validate=>input.each((k, v)=>{
                                let t=$(v);
                                validate(t, ()=>t.val()=='');
                            }),
                            validate=>validate(pass, ()=>pass.val().match(/^[\w\!\@\#]{6,13}$/) == null),
                            validate=>validate(pass2, ()=>pass.val() != pass2.val() || pass2.val() == ''),
                            validate=>validate(tel, ()=>!tel.val().match(/^1\d{10}$/)),
                            validate=>validate(protocal, ()=>{
                                console.log(protocal.is(':checked'))
                                return !protocal.is(':checked')
                            })
                        ], layer, function (res) {
                            yunu.msg_t('注册成功', function () {
                                // if (typeof callback === 'function') {
                                //     callback(res);
                                // } else if (res.DOWNLOAD_REFERER) {
                                //     window.location.replace(res.DOWNLOAD_REFERER);
                                // } else {
                                //     window.location.replace(global_app+"/");
                                // }
                                if (typeof callback === 'function') {
                                    callback(res);
                                }else {
                                    window.location.reload()
                                }
                            });
                        });
                    }
                });
            });
        },
        login(callback) {
            layui.use('layer', function(){
                layer = layui.layer;
                layer.closeAll('page');
                layer.open({
                    type: 1,
                    skin: 'login_layer',
                    title: false,
                    area: ['415px','384px'],
                    // shadeClose: true, //开启遮罩关闭
                    content: `<div class="login_popup">
                        <div class="login_popup_tit">账号密码登录</div>
                        <form id="form_login" method="POST">
                            <div class="clearfix mb15">
                                <div class="fr"><a class="color" href="javascript:yunu.login_tel();">短信验证码登录 >></a></div>
                            </div>
                            <div class="form_items">
                                <input type="text" name="username" value="" placeholder="请输入您的用户名或手机号">
                                <font class="font_empty">请输入用户名或手机号</font>
                                <font class="font_error">用户不存在</font>
                            </div>
                            <div class="form_items">
                                <input id="password" type="password" name="password" value="" placeholder="请输入您的密码">
                                <font class="font_empty">请输入密码</font>
                                <font class="font_error">密码错误</font>
                            </div>
                            <div class="form_items">
                                <input class="disabled" type="submit" value="登  录" disabled>
                            </div>
                            <div class="form_items_b">
                                点击 “登录” 即表示同意<a class="color" href="http://www.yunucms.com/notice/295.html ">《服务协议》</a>
                            </div>
                            <div class="clearfix mt15">
                                <div class="fr"><a class="color" href="javascript:yunu.register();">免费注册 >></a></div>
                            </div>
                        </form>
                    </div>`,
                    success: function(layero, index){
                        var adopt = true;
                        var form = $('#form_login');
                        var input = form.find('input[type!="submit"]');
                        var submit = form.find('input[type="submit"]');
                        submit.prop('disabled', false).removeClass('disabled');
                        yunu.submit(global_app+"/Login/login.html", form, submit, [
                            validate=>input.each((k, v)=>{
                                let t=$(v);
                                validate(t, ()=>t.val()=='');
                            })
                        ], layer, function (res) {
                            yunu.msg_t('登录成功', function () {
                                // if (typeof callback === 'function') {
                                //     callback(res);
                                // }else if (res.DOWNLOAD_REFERER) {
                                //     window.location.replace(res.DOWNLOAD_REFERER);
                                // } else {
                                //     window.location.replace(global_app+"/User/Index/index.html");
                                // }
                                if (typeof callback === 'function') {
                                    callback(res);
                                }else {
                                    window.location.reload()
                                }
                            });
                        });
                    }
                });
            });
        },
        login_tel(callback) {
            layui.use('layer', function(){
                layer = layui.layer;
                layer.closeAll('page');
                layer.open({
                    type: 1,
                    skin: 'login_layer',
                    title: false,
                    area: ['415px','384px'],
                    // shadeClose: true, //开启遮罩关闭
                    content: `<div class="login_popup">
                        <div class="login_popup_tit">短信验证码登录</div>
                        <form id="form_login_tel" method="POST">
                            <div class="clearfix mb15">
                                <div class="fr"><a class="color" href="javascript:yunu.login();">账号登录 >></a></div>
                            </div>
                            <div class="form_items">
                                <input id="tel" type="tel" name="tel" value="" placeholder="请输入您手机号码">
                                <font class="font_empty">请输入手机号码</font>
                                <font class="font_error">手机号码不存在</font>
                            </div>
                            <div class="form_items yzm" id="yzm">
                                <input type="text" name="smscode" value="" placeholder="请输入您的验证码">
                                <a href="javascript:;">获取验证码</a>
                                <font class="font_empty">请输入验证码</font>
                                <font class="font_error">验证不正确</font>
                            </div>
                            <div class="form_items">
                                <input class="disabled" type="submit" value="登  录" disabled>
                            </div>
                            <div class="form_items_b">
                                点击 “登录” 即表示同意<a class="color" href="http://www.yunucms.com/notice/295.html ">《服务协议》</a>
                            </div>
                            <div class="clearfix mt15">
                                <div class="fr"><a class="color" href="javascript:yunu.register();">免费注册 >></a></div>
                            </div>
                        </form>
                    </div>`,
                    success: function(layero, index){
                        var adopt = true;
                        var form = $('#form_login_tel');
                        var input = form.find('input[type!="submit"]');
                        var tel = form.find('#tel');
                        var submit = form.find('input[type="submit"]');
                        yunu.yzm(form, tel, layer, true);
                        submit.prop('disabled', false).removeClass('disabled');
                        yunu.submit(global_app+"/Login/login_tel.html", form, submit, [
                            validate=>input.each((k, v)=>{
                                let t=$(v);
                                validate(t, ()=>t.val()=='');
                            }),
                            validate=>validate(tel, ()=>!tel.val().match(/^1\d{10}$/))
                        ], layer, function (res) {
                            yunu.msg_t('登录成功', function () {
                                // if (typeof callback === 'function') {
                                //     callback();
                                // }else if (res.DOWNLOAD_REFERER) {
                                //     window.location.replace(res.DOWNLOAD_REFERER);
                                // } else {
                                //     window.location.replace(global_app+"/User/Index/index.html");
                                // }
                                if (typeof callback === 'function') {
                                    callback(res);
                                }else {
                                    window.location.reload()
                                }
                            });
                        });
                    }
                });
            });
        },
        message(callback) {
            layui.use('layer', function(){
                layer = layui.layer;
                layer.closeAll('page');
                layer.open({
                    type: 1,
                    skin: 'message_layer',
                    title: false,
                    area: ['489px','711px'],
                    shadeClose: true, //开启遮罩关闭
                    content: `<div class="message_popup">
                        <div class="message_popup_tit">
                            <h5>申请代理商</h5>
                            <p>尊敬的用户，您如实填写完表单后，云优CMS会主动与您联系并将<span class="color">招商PPT</span>发送至您的邮箱！</p>
                        </div>
                        <form id="form_message" method="POST" action="/Guestbook/index.html">
                            <div class="form_items">
                                <input type="text" name="cname" placeholder="请输入您的公司全称">
                                <font class="font_empty">请输入公司全称</font>
                                <font class="font_error">填写错误，请填写公司全称，字数在8~20个字之间，且必须中文</font>
                            </div>
                            <div class="form_items">
                                <input type="name" name="name" placeholder="请输入您的联系人姓名">
                                <font class="font_empty">请输入联系人姓名</font>
                                <font class="font_error">填写错误，姓名字数在2~4个字之间，且必须中文</font>
                            </div>
                            <div class="form_items yzm" id="yzm">
                                <input type="tel" name="tel" placeholder="请输入您的手机号码">
                                <a href="javascript:;">获取验证码</a>
                                <font class="font_empty">请输入手机号码</font>
                                <font class="font_error">请输入正确的手机号码</font>
                            </div>
                            <div class="form_items">
                                <input type="text" name="smscode" placeholder="请输入您的验证码">
                                <font class="font_empty">请输入验证码</font>
                                <font class="font_error">验证不正确</font>
                            </div>
                            <div class="form_items">
                                <input type="email" name="email" placeholder="请输入您的邮箱">
                                <font class="font_empty">请输入邮箱</font>
                                <font class="font_error">请输入正确的邮箱</font>
                            </div>
                            <div class="form_items">
                                <input type="submit" value="提交资料">
                            </div>
                        </form>
                    </div>`,
                    success: function(layero, index){
                        var form = $('#form_message');
                        var input = form.find('input[type!="submit"]');
                        var cname = form.find('input[name="cname"]');
                        var name = form.find('input[name="name"]');
                        var tel = form.find('input[name="tel"]');
                        var email = form.find('input[name="email"]');
                        var submit = form.find('input[type="submit"]');
                        yunu.yzm(form, tel, layer, true);
                        yunu.submit(global_app+"/Guestbook/index", form, submit, [
                            validate=>input.each((k, v)=>{
                                let t=$(v);
                                validate(t, ()=>t.val()=='');
                            }),
                            validate=>validate(cname, ()=>!cname.val().match(/^[\u4E00-\u9FA5]{8,20}$/)),
                            validate=>validate(name, ()=>!name.val().match(/^[\u4E00-\u9FA5]{2,4}$/)),
                            validate=>validate(tel, ()=>!tel.val().match(/^1\d{10}$/)),
                            validate=>validate(email, ()=>!email.val().match(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)),
                        ], layer, function (res) {
                            layer.closeAll('page');
                            layer.open({
                                type: 1,
                                title: false,
                                skin: 'message_success_layer',
                                area: ['457px','343px'],
                                time: 2000,
                                content: '<img src="'+global_app+'/Public/Index/img/message_success.png"><h2>提交成功</h2><p>已收到您提交的信息，我们将尽快与您取得联系，谢谢！</p>'
                            });
                        });
                    }
                });
            });
        },
        template_buy($id) {
            layui.use(['element', 'layer', 'form'], function(){
                var element = layui.element;
                var layer = layui.layer;
                var form = layui.form;

                layer.load(0, {
                    skin: 'msg_loading',
                    shade: [0.1, '#000']
                });
                $.ajax({
                    url: '/Jsapi/get_template_info',
                    type: 'POST',
                    data: {id: $id},
                }).done(function(res) {
                    if (res.state == 1) {
                        layer.open({
                            type: 1,
                            title: '网站开通流程',
                            content: `
                                <div class="template_buy_content layui-form" id="template_buy">
                                    <input type="hidden" name="id" value="${res.data.id}" />
                                    <input type="hidden" name="space" value="0" />
                                    <div class="layui-collapse" lay-accordion="">
                                        <div class="layui-colla-item">
                                            <h2 class="layui-colla-title">模板</h2>
                                            <div class="layui-colla-content layui-show">
                                                <div class="template_buy_t">
                                                    <div class="template_pic">
                                                        <img src="${res.data.pc_img}" alt="">
                                                    </div>
                                                    <div class="template_info">
                                                        <h2>${res.data.title}</h2>
                                                        <div class="money">
                                                            ${
                                                                res.data.hasOwnProperty('omoney') ?
                                                                `
                                                                <img class="vat mr5" src="/Public/Public/img/s11_2019/8.png" alt="">
                                                                <span class="f16" style="color: #f60;">¥</span>
                                                                <span class="f26" style="color: #f60;">${res.data.money}</span>
                                                                <span class="f12 vat ml10" style="color: #939393;">原价</span>
                                                                <span class="f12 vat" style="color: #939393;text-decoration: line-through;">¥</span>
                                                                <span class="f16 vat" style="color: #939393;text-decoration: line-through;"> ${res.data.omoney}</span>
                                                                `
                                                                :
                                                                `<span class="f20 vam mr10">¥ ${res.data.money} 元</span>
                                                                <img class="vam" src="/Public/Index/img/gift.png" alt="">
                                                                <span class="vab">买模板送授权</span>`
                                                            }
                                                        </div>
                                                        <ul>
                                                            <li>产品编号： ${res.data.number}</li>
                                                            <li>主题版本： v${res.data.version}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p>模板所有信息均可在后台或模板文件中进行修改，如具备专业 web 前端技术，还可以对整体布局进行修改。</p>
                                            </div>
                                        </div>
                                        <div class="layui-colla-item">
                                            <h2 class="layui-colla-title">空间</h2>
                                            <div class="layui-colla-content">
                                                <div class="layui-tab" lay-filter="space">
                                                    <ul class="layui-tab-title">
                                                        <li>选购空间</li>
                                                        <li class="layui-this">已有空间</li>
                                                    </ul>
                                                    <div class="layui-tab-content">
                                                        <div class="layui-tab-item lh24">
                                                            <p class="tc_red">所有主机均不可以发布非法及管控消息，违者一律永久关停处理且不退款；</p>
                                                            <p><span class="tc_green">国内主机需要备案才能绑定正式域名，</span>如你的域名已在阿里云备案，建议你选择对应提供商的主机。购买阿里云备案主机后，会提供一个阿里云备案码。</p>
                                                            <ul class="template_buy_space">
                                                                <li>
                                                                    <span class="mr10">价格</span>
                                                                    <span class="f24 tc_red"><i class="template_buy_space_money">299</i>元/1年</span>
                                                                </li>
                                                                <li>
                                                                    <span class="mr10">类型</span>
                                                                    <span style="width: 158px;display: inline-block;">
                                                                        <select name="type" lay-verify="required">
                                                                            <option value="0">阿里云国内主机</option>
                                                                            <option value="1">阿里云香港主机</option>
                                                                        </select>
                                                                    </span>
                                                                    <span class="mr10 ml10">容量</span>
                                                                    <span style="width: 76px;display: inline-block;">
                                                                        <select name="capacity" lay-verify="required" lay-filter="capacity">
                                                                            <option value="2">2G</option>
                                                                            <option value="5">5G</option>
                                                                        </select>
                                                                    </span>
                                                                    <span class="mr10 ml10">购买时长</span>
                                                                    <span style="width: 76px;display: inline-block;">
                                                                        <select name="year" lay-verify="required" lay-filter="year">
                                                                            <option value="1">1年</option>
                                                                            <option value="2">2年</option>
                                                                            <option value="3">3年</option>
                                                                            <option value="5">5年</option>
                                                                        </select>
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="layui-tab-item lh24 layui-show">
                                                            <p class="f16 pt10 pb10">云优CMS 运行环境要求</p>
                                                            <p>操作系统：Linux / Unix / Windows</p>
                                                            <p>软件环境：Apache / Nginx / IIS + PHP 5.3 ~ 7.0 + MySQL 5.0 及以上</p>
                                                            <p class="c_red">友情提示：非官方空间请确保满足以上安装环境，并配置好服务器相关权限及函数参数，官方售后不包含免费环境配置服务！</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="template_buy_content_b">
                                        <div>总计：<span class="template_buy_total">${res.data.money}</span> 元</div>
                                        <div><button class="settlement" lay-submit lay-filter="template_buy">去结算</button></div>
                                    </div>
                                </div>
                            `,
                            skin: 'template_buy',
                            area: '600px',
                            success: function(layero, index){
                                element.init();
                                form.render('select');

                                var capacity_money_config = {2: 299, 5: 399};
                                var capacity = 2;
                                var year = 1;
                                var money_compute = function () {
                                    var money = capacity_money_config[capacity];
                                    $('#template_buy .template_buy_space_money').html(money);
                                    $('#template_buy .template_buy_total').html((money * year + parseFloat(res.data.money)).toFixed(2));
                                }
                                element.on('tab(space)', function(data){
                                    if (data.index == 0) {
                                        $('#template_buy input[name="space"]').val(1);
                                        money_compute();
                                    } else {
                                        $('#template_buy input[name="space"]').val(0);
                                        $('#template_buy .template_buy_total').html(res.data.money);
                                    }
                                });
                                form.on('select(capacity)', function(data){
                                    capacity = data.value;
                                    money_compute();
                                });
                                form.on('select(year)', function(data){
                                    year = data.value;
                                    money_compute();
                                });
                                form.on('submit(template_buy)', function(data){
                                    layer.load(0, {
                                        skin: 'msg_loading',
                                        shade: [0.1, '#000']
                                    });
                                    $.post('/User/Pay/template_buy', data.field, function(res) {
                                        if (res.state == 1) {
                                            layer.closeAll();
                                            yunu.open({
                                                content: '恭喜您，成功购买云优模板',
                                                btn2: function(index, layero){
                                                    window.location.href = "/User/Business/website/yd/1";
                                                }
                                            });
                                        }else if(res.state == 2){
                                            yunu.open({
                                                content: '抱歉，购买失败，您的可用金额不足是否立即充值？',
                                                btn: ['取消','立即充值'],
                                                btn2: function(index, layero){
                                                    window.location.href = "/User/Finance/recharge";
                                                }
                                            });
                                        }else if(res.state == 401){
                                            yunu.swal({
                                                text: '请登录',
                                                confirmButtonText: "登录"
                                            }, function(){
                                                swal.close();
                                                yunu.login();
                                            });
                                        }else{
                                            swal("失败", res.mess, "error");
                                        }
                                    }).always(function() {
                                        layer.closeAll('loading');
                                    });
                                    return false;
                                });
                            }
                        });
                    } else {
                        layer.msg('获取失败');
                    }
                }).fail(function() {
                    layer.msg('获取失败');
                }).always(function() {
                    layer.closeAll('loading');
                });
            });
            return this;
        },
        like() {
            $('.like').on('click', function () {
                var self = $(this);
                var id = self.attr('data-id');
                var key = true;
                var ls = {};
                if (window.localStorage && localStorage.getItem('like')) {
                    ls = JSON.parse(localStorage.getItem('like'));
                }
                if (self.hasClass('move') || ls.hasOwnProperty(id)) {
                    key = false;
                }
                if (key) {
                    var num = self.addClass('move').find('span');
                    num.html(parseInt(num.html()) + 1);
                    $.post('/Article/setLikeInc', {id: id});
                    if (window.localStorage) {
                        ls[id] = 1;
                        localStorage.setItem('like', JSON.stringify(ls));
                    }
                } else {
                    yunu.msg_t('已赞赏', {
                        skin : 'layui-layer-hui msg_error',
                        time : 3000,
                        area: ['123px', '48px']
                    });
                }
            });
        }
    });

    $(() => {
        if ($.fn.tooltip) $('[data-toggle="tooltip"]').tooltip();
    });

})(jQuery, window, document);
